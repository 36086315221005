import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { useStore } from "../../context/NewStoreContext"
import DropdownArrow from "../../assets/svg/dropdown-arrow.svg"
import Tooltip from "../new-ui/tooltip"
import Typography from "../ui/Typography"

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    max-width: 600px;
    margin: 0 auto;

    > table {
      margin: 0px;
    }

    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }
`

const Header = styled.div`
  margin: 0px 16px;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  h2 {
    font-size: 24px;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    margin: 0px;
    h2 {
      font-size: 36px;
    }
  }
`

const TextContainer = styled.div`
  margin: 0px 16px;
  font-size: 15px;
  line-height: 1.4;
  padding-top: 24px;

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    margin: 0px;
  }
`

const TableContainer = styled.div`
  overflow-y: visible;
  overflow-x: scroll;
  position: relative;
  width: 100%;

  padding-top: 40px;

  ${(props) => props.theme.breakpointsLegacy.desktop} {
  }
`

const Table = styled.table`
  // table-layout: fixed;
  line-height: 22px;
  border-collapse: collapse;
  font-size: 15px;

  td {
    border: none;
  }

  th {
    border: none;
  }

  .table-header {
    font-weight: bold;
    margin: 0;
  }
`

const Row = styled.tr`
  background-color: ${(props) =>
    props.isGrey && props.theme.colors.grayscale[200]};
  height: 56px;
  font-size: 14px;

  td:first-of-type,
  th:first-of-type {
    padding-left: 16px;
  }

  td,
  th {
    padding-right: 20px;
    padding-left: 20px;
    white-space: nowrap;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    font-size: 15px;

    td,
    th {
      padding-right: 32px;
      padding-left: 32px;
    }
  }
`

const TabNav = styled.div`
  display: flex;
  flex: auto;
  font-size: 14px;
  box-sizing: border-box;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayscale[300]};
`

const TabNavItem = styled.div`
  ${Typography.Small}
  color: ${(props) => props.selected && props.theme.colors.primary};
  border-bottom: 1px solid
    ${(props) => (props.selected ? props.theme.colors.primary : "none")};
  padding-right: 20px;
  font-weight: ${(props) => props.selected && "bold"};
  font-size: 14px;
  padding-bottom: 5px;
  cursor: pointer;
  flex-basis: auto;
  flex-grow: 0;
  margin-bottom: -1px;
`

const BodyContainer = styled.div`
  margin: 30px 16px;

  h2 {
    font-size: 24px;
    font-weight: normal;
  }

  h3 {
    font-size: 16px;
    margin: 0;

    margin-top: 20px;
  }

  p {
    margin: 12px 0;
  }

  .bold {
    font-weight: bold;
    margin-top: 10px;
  }

  img {
    width: 100%;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    margin: 64px 0;

    .img-full:first-of-type:nth-last-of-type(1) {
      width: 100%;
    }

    .img-full:first-of-type:nth-last-of-type(2),
    .img-full:first-of-type:nth-last-of-type(2) ~ .img-full {
      width: 50%;
      display: inline-block;
    }

    h2 {
      font-size: 28px;
      font-weight: normal;
    }
  }
`

const DropdownWrapper = styled.div`
  display: flex;

  > div:last-of-type {
    margin-left: 10px;
  }
`

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  .select-icon {
    margin-left: -18%;
  }
`

export const Select = styled.select`
  background-color: ${(props) => props.theme.colors.grayscale.white};
  border: ${(props) => props.theme.borders.grey};
  width: ${(props) => (props.fullWidth ? "100%" : "90px")};
  padding: 0 5px;
  height: 32px;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`

const SelectContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${SelectWrapper}:not(${SelectWrapper}:first-of-type) {
    margin-left: 4px;
  }

  ${SelectWrapper}:last-of-type {
    margin-right: -8px;
  }
`

const FurtherQuestions = styled.div`
  margin: 16px;
  opacity: 0.5;
  padding-bottom: 80px;

  a {
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    margin: 20px 0;
  }
`

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: function createBoldText(text) {
      return <Bold>{text}</Bold>
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: function createParagraph(node, children) {
      return <Text>{children}</Text>
    },
    [BLOCKS.EMBEDDED_ASSET]: function createImage(node) {
      return (
        <GatsbyImage
          className="img-full"
          image={node.data.target.gatsbyImageData}
          alt="contentful-image"
        />
      )
    },
  },
}

const SizeGuide = ({
  sizeGuideGroups,
  initialGender,
  initialSizing = "cm",
  selectComponent,
  title,
}) => {
  const [selected, setSelected] = useState(sizeGuideGroups?.[0] || null)
  const [gender, setGender] = useState(initialGender)
  const [sizing, setSizing] = useState(initialSizing)
  const { cart } = useStore()

  useEffect(() => {
    setSizing(initialSizing)
  }, [initialSizing])

  useEffect(() => {
    if (sizeGuideGroups) {
      setSelected(sizeGuideGroups[0])
    }
  }, [sizeGuideGroups])

  if (!selected?.sizeGuides) {
    return null
  }

  const genderOptions = selected?.sizeGuides.reduce((acc, next) => {
    if (next.gender) {
      acc.push(next.gender)
    } else {
      acc.push("unisex")
    }
    return acc
  }, [])

  const data =
    selected?.sizeGuides.length === 1
      ? selected.sizeGuides[0]
      : selected.sizeGuides.find(
          (s) => s.gender.toLowerCase() === gender.toLowerCase()
        )

  const isMeasurement = data?.sizeTable?.isMeasurement
  let tableData = data?.sizeTable?.sizeTable.tableData

  if (tableData?.[0]?.[0].toLowerCase() === "country") {
    tableData.sort((a, b) => a[0].localeCompare(b[0]))
  }

  const handleSizeRender = (text) => {
    if (isMeasurement) {
      if (sizing === "cm") {
        return text.split("/")[0]
      }
      if (sizing === "inch") {
        if (text.split("/")[1]) {
          return text.split("/")[1]
        } else {
          return text
        }
      }
    } else {
      return text
    }
  }

  return (
    <Content>
      <Header>
        <div className="flex">
          {title ? title : <h1>Size guide</h1>}

          <div className="desktop">
            <DropdownWrapper>
              {genderOptions?.length > 1 && (
                <SizeGuideDropdown
                  onSelect={(o) => setGender(o)}
                  options={genderOptions}
                  value={gender}
                />
              )}
              {isMeasurement && (
                <SizeGuideDropdown
                  onSelect={(o) => setSizing(o)}
                  options={["cm", "inch"]}
                  value={sizing}
                />
              )}
            </DropdownWrapper>
          </div>
          <div className="mobile">
            <SelectContainer>
              {genderOptions?.length > 1 && (
                <SelectWrapper>
                  <Select
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value)
                    }}
                  >
                    {genderOptions.map((gender) => {
                      return (
                        <option key={`gender-option-${gender}`} value={gender}>
                          {gender}
                        </option>
                      )
                    })}
                  </Select>
                  <DropdownArrow className="select-icon" />
                </SelectWrapper>
              )}
              {isMeasurement && (
                <SelectWrapper>
                  <Select
                    value={sizing}
                    onChange={(e) => {
                      setSizing(e.target.value)
                    }}
                  >
                    <option value="cm">cm</option>
                    <option value="inch">inch</option>
                  </Select>
                  <DropdownArrow className="select-icon" />
                </SelectWrapper>
              )}
            </SelectContainer>
          </div>
        </div>
        {selectComponent && selectComponent}
        {sizeGuideGroups?.length >= 1 && (
          <TabNav>
            {sizeGuideGroups.map((i) => (
              <TabNavItem
                onClick={() => setSelected(i)}
                key={`size-guide-${i.id}`}
                selected={i.id === selected.id}
              >
                {i.title}
              </TabNavItem>
            ))}
          </TabNav>
        )}
      </Header>
      {data?.text && (
        <TextContainer
          dangerouslySetInnerHTML={{
            __html: data?.text.childMarkdownRemark.html,
          }}
        />
      )}

      {selected && (
        <div>
          <TableContainer>
            <Table>
              <thead>
                <Row>
                  {tableData?.[0].map((head, index) => (
                    <th key={index} className="table-header">
                      {head}
                    </th>
                  ))}
                </Row>
              </thead>
              <tbody>
                {tableData?.map((row, index) => {
                  if (index === 0) return null
                  return (
                    <Row key={index} isGrey={index % 2 !== 0}>
                      {row.map((val, index) => {
                        const text = handleSizeRender(val).trim()
                        const header = text.split("[")[0]
                        const tooltip = text.match(/\[(.*?)\]/)

                        return index === 0 ? (
                          <td key={`${index}-${val}`} className="first-col">
                            {tooltip ? (
                              <Tooltip text={header} popover={tooltip[1]} />
                            ) : (
                              header
                            )}
                          </td>
                        ) : (
                          <td
                            key={`${index}-${val}`}
                            data-th={tableData[0][index].value}
                          >
                            {handleSizeRender(val).trim()}
                          </td>
                        )
                      })}
                    </Row>
                  )
                })}
              </tbody>
            </Table>
          </TableContainer>
        </div>
      )}
      {data?.body && (
        <BodyContainer>{renderRichText(data.body, options)}</BodyContainer>
      )}
      <FurtherQuestions>
        {cart?.shipping_address?.country_code?.toLowerCase() === "us" ||
        cart?.shipping_address?.country_code?.toLowerCase() === "ca" ? (
          <>
            Need further guidance? We are at your disposal so please do not
            hesitate in contacting our customer care via email at
            sizing@teklafabrics.com or via phone on +1 (909) 487-2811,
            Monday-Friday 9AM-5PM (CET+1).
          </>
        ) : (
          <>
            Need further guidance? We are at your disposal so please do not
            hesitate in contacting our customer care via email at
            sizing@teklafabrics.com or via phone on +45 5251 2545, Monday-Friday
            9AM-5PM (CET+1).
          </>
        )}
      </FurtherQuestions>
    </Content>
  )
}

export default SizeGuide

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  font-size: 15px;
  font-weight: 400;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 100%;
  border: ${(props) => props.theme.borders.grey};

  :hover {
    cursor: pointer;
  }

  .current {
    width: 90px;
    height: 32px;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
      props.open && props.theme.colors.grayscale[200]};
  }

  .value {
    display: flex;
    align-items: center;
    line-height: 19px;
    padding: 0 12px;
    justify-content: space-between;

    &.bold {
      font-weight: bold;
    }
  }

  .dropdown-icon {
    display: block;

    transform: ${(props) => props.open && "rotate(180deg)"};
  }

  .options {
    position: absolute;
    top: 100%;
    display: ${(props) => (props.open ? "flex" : "none")};
    font-weight: 400;

    flex-direction: column;
    width: 90px;
    border-left: ${(props) => props.theme.borders.grey};
    border-right: ${(props) => props.theme.borders.grey};
    border-bottom: ${(props) => props.theme.borders.grey};
    background-color: ${(props) => props.theme.colors.grayscale.white};

    .option {
      display: flex;
      align-items: center;
      height: 28px;
      padding-left: 12px;

      :hover {
        background-color: ${(props) => props.theme.colors.grayscale[200]};
      }
    }
    .option:first-of-type {
      margin-top: 10px;
    }
    .option:last-of-type {
      margin-bottom: 5px;
    }
  }
`

export const SizeGuideDropdown = ({ value, options, onSelect }) => {
  const [open, setOpen] = useState(false)

  return (
    <DropdownContainer open={open}>
      <div className="value bold current" onClick={() => setOpen(!open)}>
        <div>{value}</div>
        <DropdownArrow className="dropdown-icon" />
      </div>

      <div className="options">
        {options.map((o, i) => (
          <div
            key={`option-${value}-${i}`}
            className="option"
            onClick={() => {
              onSelect(o)
              setOpen(false)
            }}
          >
            {o}
          </div>
        ))}
      </div>
    </DropdownContainer>
  )
}
